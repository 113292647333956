  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/3-29-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/paca/'>Trouvez un expert près de chez vous</a></li><li>Expert sécheresse X pages par ville à 100 km autour d'Aix</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Le cabinet RV Expertises, Expert sécheresse et fissures en Pays d’Aix</h1>

<p>Vous avez subi un sinistre sur votre ouvrage à la suite d' un épisode de sécheresse ?</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expert sécheresse X pages par ville à 100 km autour d'Aix" />
</div>

<p>Votre ouvrage présente des fissures ? </p>
<p>Sont-elles graves et conséquentes ?</p>
<p>Menacent-elles de ruine votre ouvrage ? </p>

<p>L’expert construction spécialiste en sinistre sécheresse et spécialiste des pathologies de la construction et des interactions sol et fondations, est à votre disposition <u>pour vous conseiller et répondre à vos questions.</u></p>

<h2>L’Expertise sécheresse, qu’est-ce-que-c’est ?</h2>

<p>L’expertise sécheresse sollicite l’intervention d’un Expert construction possédant de solides connaissances en pathologies de la construction.</p>

<p>L’objectif d’une expertise sécheresse est ici de déterminer de façon neutre et impartiale, les causes et origines des fissures sur votre ouvrage, de déterminer si la sécheresse est la cause déterminante d’apparition des fissures sur votre ouvrage.</p>

<p>L’expertise sécheresse vous oriente également sur les travaux de reprises les plus adaptés. </p>

<h2>L’Expert sécheress pour défendre vos intérêts </h2>

<p>Lorsque votre assurance vous refuse la garantie de votre sinistre sécheresse, vous avez la possibilité d’être assisté par votre propre expert : l’expert d’assuré sécheresse.</p>

<p>L’expert sécheresse vous représente alors techniquement et endosse un rôle de médiateur dans un objectif de défense de vos intérêts. </p>

<p>L'expert sécheresse effectue une visite technique sur site, analyse l’étude de sol si disponible ou la préconise. L’expert sécheresse est en mesure de déterminer <u>si la sécheresse constitue le facteur déterminant d’apparition des fissures sur votre ouvrage</u>, condition principale d’ouverture à votre indemnisation. </p>

<p>L’expert sécheresse confronte ses arguments avec l’expert d’assurance lors d’une contre-expertise amiable afin d’obtenir en fin d’opérations d’expertise, la juste indemnisation de votre sinistre.</p>

<h2>Le cabinet RV Expertises, spécialiste des dossiers sécheresse en défense de l’assuré</h2>

<p>Le traitement des dossiers sécheresse en recours amiable est la spécialité du cabinet RV Expertises. </p>

<p>Notre équipe d’Experts spécialisés mettent tout en œuvre pour un accompagnement personnalisé afin d’assurer la défense de vos intérêts jusqu’à la juste indemnisation de votre sinistre.</p>

<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Demandez un devis</a>
    </div>
</div>                         
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/paca/expert-secheresse-environ-aix-en-provence/' className='active'>Expert sécheresse X pages par ville à 100 km autour d'Aix</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details